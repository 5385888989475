<template>
  <form
      @submit.prevent="submitJob"
      autocomplete="off"
      class="w-full max-h-screen bg-background flex flex-col relative"
      :class="{ 'overflow-y-hidden': isBusy }"
  >
    <div
        v-show="isBusy"
        class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
    >
      <spinner :color="'text-black'" :size="10"/>
    </div>
    <page-header :title="pageTitle" :info="getJobId" :enableLogicalBack="true" back-to=" ">
      <button
          v-if="!readOnly"
          @click="saveJob"
          type="button"
          class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8"/>
        </svg>
        <span>Save</span>
      </button>
    </page-header>

    <main class="pr-6 pl-6  bg-background flex-1 overflow-y-scroll">
      <!-- First Row with Two Responsive Columns -->
      <div class="flex flex-col md:flex-row w-full">
        <!-- Column 1 -->
        <div class="w-full md:w-1/2 bg-white p-5 mt-6 md:mr-3 rounded shadow-md">
          <div class="flex items-center mb-5">
            <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
              Select Customer
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>
          <div class="flex flex-col md:flex-row gap-8">
            <!-- Left Column -->
            <div class="flex-1 space-y-2">
              <div>
                <label class="uppercase font-semibold text-sm mb-2">Customer</label>
                <ContactAutocomplete class="w-full" :class="{ 'readonly': readOnly }"
                                     v-model="schedule.customer"
                                     @contactSelected="handleContactSelected"
                                     @contactCleared="handleContactCleared" :forceSelection="true" :is_customer="true"
                                     />
              </div>

              <div v-if="customer.address" class="flex items-center space-x-2">
                <!-- Address Icon -->
                <svg class="h-5 w-5 text-orange-700" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                     stroke-width="2">
                  <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z"/>
                  <circle cx="12" cy="10" r="3"/>
                </svg>
                <p class="text-sm">{{ customer.address }}</p>
              </div>

              <div v-if="customer.people" class="space-y-2">
                <div v-if="customer.people.job_title" class="flex items-center space-x-2">
                  <!-- Job Title Icon -->
                  <svg class="h-5 w-5 text-orange-700" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                       stroke-width="2">
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"/>
                    <circle cx="12" cy="7" r="4"/>
                  </svg>
                  <p class="text-sm">{{ customer.people.job_title }}</p>
                </div>

                <div class="flex items-center space-x-2" v-if="customer.people.firstname || customer.people.lastname">
                  <!-- Name Icon -->
                  <svg class="h-5 w-5 text-orange-700" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                       stroke-width="2">
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"/>
                    <circle cx="12" cy="7" r="4"/>
                  </svg>
                  <p class="text-sm">{{ customer.people.firstname }}</p>
                </div>

                <div class="flex items-center space-x-2" v-if="customer.people.email">
                  <!-- Email Icon -->
                  <svg class="h-5 w-5 text-orange-700" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                       stroke-width="2">
                    <path
                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                    />
                    <path d="M22 6l-10 7L2 6"/>
                  </svg>
                  <p class="text-sm">{{ customer.people.email }}</p>
                </div>

                <div class="flex items-center space-x-2" v-if="customer.people.mobile || customer.people.telephone">
                  <!-- Phone Icon -->
                  <svg class="h-5 w-5 text-orange-700" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                       stroke-width="2">
                    <path
                        d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z"
                    />
                  </svg>
                  <p class="text-sm">{{ customer.people.mobile || customer.people.telephone }}</p>
                </div>

                <!-- VTS Section -->
                <div class="flex items-center space-x-2" v-if="customer.vts_number">
                  <span class="font-bold text-orange-700 uppercase">VTS</span>
                  <p class="text-sm">{{ customer.vts_number }}</p>
                </div>
              </div>
            </div>

            <!-- Right Column -->
            <div class="flex-1 space-y-2 md:overflow-auto ">
              <div>
                <label class="uppercase font-semibold text-sm mb-2">Customer Notes</label>
                <textarea
                    readonly
                    v-model="customer.comments"
                    class="text-md leading-snug w-full focus:outline-none bge-input pl-2 pr-2 pb-3 pt-2 rounded h-full"
                    placeholder="Notes..."
                    rows="8"
                ></textarea>
              </div>
            </div>
          </div>

        </div>

        <!-- Column 2 -->
        <div class="w-full md:w-1/2 bg-white p-5 mt-6 md:ml-3 rounded shadow-md">
          <div class="flex items-center mb-2">
            <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
              Set Schedule
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>


          <div class="flex">

            <div class="w-1/2 p-2">
              <div>
                <label class="uppercase font-semibold text-sm mb-2">Engineer</label>
                <StaffAutocomplete class="w-full" :class="{ 'readonly': readOnly }" v-model="schedule.engineer"
                                   :forceSelection="true"/>
              </div>
            </div>


            <div class="w-1/4 p-2">
              <label class="uppercase font-semibold text-sm">Date</label>
              <Calendar
                  dateFormat="dd/mm/yy"
                  v-model="schedule.scheduled_date" :showIcon="true"
                  :class="{ 'readonly': readOnly }"
              />
            </div>


            <div class="w-1/4 p-2">
              <label class="uppercase font-semibold text-sm">Time</label>
              <Calendar
                  dateFormat="dd/mm/yy"
                  v-model="schedule.scheduled_time" :timeOnly="true"
                  :class="{ 'readonly': readOnly }"
              />
            </div>
          </div>


          <div class="flex mt-4">

            <div class="w-1/2 p-2 flex flex-col">
              <label class="uppercase font-semibold text-sm">Notes</label>
              <textarea
                  :readonly="readOnly"
                  v-model="schedule.notes"
                  class="text-md leading-snug w-full focus:outline-none bge-input bge-input-spacing rounded h-full"
                  placeholder="Notes..."
                  rows="4"
              ></textarea>
            </div>


            <div class="w-1/4 p-2 flex flex-col justify-between">
              <Toggle
                  :disabled="readOnly"
                  labelPosition="left"
                  label="Confirmed"
                  :checked="schedule.is_confirmed"
                  labelClasses="uppercase font-semibold text-sm "
                  @value-changed="(value) => (schedule.is_confirmed = value)"
              />

              <div></div>
              <label class="uppercase font-semibold text-sm mt-6">Confirmed By</label>
              <input v-model="schedule.confirmed_by" :readonly="readOnly" class="bge-input bge-input-spacing rounded"
                     type="text"/>
            </div>


            <div class="w-1/4 p-2 flex flex-col justify-between">
              <Toggle
                  :disabled="readOnly"
                  labelPosition="left"
                  label="Site Visit"
                  :checked="schedule.is_site_visit"
                  labelClasses="uppercase font-semibold text-sm"
                  @value-changed="(value) => (schedule.is_site_visit = value)"
              />

              <div class="flex-grow "></div>
              <Toggle
                  :disabled="readOnly"
                  class="mb-2 ml-2"
                  labelPosition="left"
                  label="Pre Pay"
                  :checked="schedule.is_pre_pay"
                  labelClasses="uppercase font-semibold text-sm"
                  @value-changed="(value) => (schedule.is_pre_pay = value)"
              />
              <div></div>
            </div>
          </div>
        </div>

      </div>

      <!-- Second Row Full Width -->
      <div class="w-full bg-white p-5 mt-6 rounded shadow-md">
        <div class="flex items-center mb-5">
          <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
            Select Equipment
          </h3>
          <hr class="flex-1 border-primary ml-4"/>
        </div>

        <DataDetailTableComponent :columns="columns" @row-click="onRowClick">
          <template v-slot:timestamp-slot-service="{ rowData }">
            {{ formatTimestamp(rowData.equipment.next_service) }}
          </template>
          <template v-slot:timestamp-slot-calibration="{ rowData }">
            {{ formatTimestamp(rowData.equipment.next_calibration) }}
          </template>
          <template v-slot:timestamp-slot-warranty="{ rowData }">
            {{ formatTimestamp(rowData.equipment.warranty_expiry) }}
          </template>

          <template v-slot:job-slot-install="{ rowData }">
            <Toggle
                :disabled="canEditScheduleEquipments(rowData)"
                class="m-2"
                labelPosition="left"
                :checked="rowData.scheduleDetail.is_install"
                @value-changed="(value) => rowData.scheduleDetail.is_install = value"
            />
          </template>
          <template v-slot:job-slot-repair="{ rowData }">
            <Toggle
                :disabled="canEditScheduleEquipments(rowData)"
                class="m-2"
                labelPosition="left"
                :checked="rowData.scheduleDetail.is_repair"
                @value-changed="(value) => rowData.scheduleDetail.is_repair = value"
            />
          </template>
          <template v-slot:job-slot-service="{ rowData }">
            <Toggle
                :disabled="canEditScheduleEquipments(rowData)"
                class="m-2"
                labelPosition="left"
                :checked="rowData.scheduleDetail.is_service"
                @value-changed="(value) => rowData.scheduleDetail.is_service = value"
            />
          </template>
          <template v-slot:job-slot-calibration="{ rowData }">
            <Toggle
                :disabled="canEditScheduleEquipments(rowData)"
                class="m-2"
                labelPosition="left"
                :checked="rowData.scheduleDetail.is_calibration"
                @value-changed="(value) => rowData.scheduleDetail.is_calibration = value"
            />
          </template>

          <template v-slot:job-slot-warranty="{ rowData }">
            <Toggle
                :disabled="canEditScheduleEquipments(rowData)"
                class="m-2"
                labelPosition="left"
                :checked="rowData.scheduleDetail.is_warranty"
                @value-changed="(value) => rowData.scheduleDetail.is_warranty = value"
            />
          </template>

          <template v-slot:job-slot-sheet="{ rowData }" v-if="job_id">
            <svg
                v-if="shouldDisplayIcon(rowData.scheduleDetail)"
                @click="CreateJobSheet(rowData)"
                :class="getIconClass(rowData.scheduleDetail)"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                viewBox="0 0 24 24"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"/>
              <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z"/>
              <path d="M9 12h6"/>
              <path d="M9 16h6"/>
            </svg>
          </template>

        </DataDetailTableComponent>
      </div>
    </main>


  </form>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Panel from "@/components/Panel.vue";
import Spinner from "@/components/Spinner.vue";
import ContactAutocomplete from '@/components/AutoComplete/ContactAutoComplete.vue';
import StaffAutocomplete from '@/components/AutoComplete/StaffAutoComplete.vue';
import AppButton from '@/components/Button.vue';
import Icon from '@/components/Icon.vue';
import notificationMixin from "@/mixins/notificationMixin";
import DataDetailTableComponent from "@/views/HR/Engineer/Schedule/Components/DataDetailTableComponent.vue";

const Toggle = () => import("@/components/Toggle.vue");
export default {
  name: "ManageSchedule",
  mixins: [notificationMixin],
  components: {
    PageHeader,
    Panel,
    Spinner,
    ContactAutocomplete,
    StaffAutocomplete,
    AppButton,
    Icon,
    Toggle,
    DataDetailTableComponent
  },
  props: {
    schedule_id: String,
    job_id: String,
  },
  data() {
    return {
      isBusy: false,
      schedule: {},
      customer: {},
      readOnly: false,
      columns: [
        {
          field: "equipment.product_code",
          header: "Code",
          sortable: true,
          custom: false,
          width: '8%',
        },
        {
          field: "equipment.name",
          header: "Description",
          sortable: true,
          custom: false,
          width: '17%',
        },
        {
          field: "equipment.serial_number",
          header: "Serial No.",
          sortable: true,
          custom: false,
          width: '10%',
        },
        {
          field: "equipment.next_calibration",
          header: "Calibration",
          sortable: true,
          custom: true,
          slotName: "timestamp-slot-calibration",
          width: '10%',
        },
        {
          field: "equipment.next_service",
          header: "Service",
          sortable: true,
          custom: true,
          slotName: "timestamp-slot-service",
          width: '10%',
        },
        {
          field: "equipment.warranty_expiry",
          header: "Expiry",
          sortable: true,
          custom: true,
          slotName: "timestamp-slot-warranty",
          width: '10%',
        },
        {
          field: "scheduleDetail.is_install",
          header: "Install",
          custom: true,
          slotName: "job-slot-install",
          width: '7%',
          headerClass: 'column-h-text-center'
        },
        {
          field: "scheduleDetail.is_repair",
          header: "Repair",
          custom: true,
          slotName: "job-slot-repair",
          width: '7%',
          headerClass: 'column-h-text-center'
        },
        {
          field: "scheduleDetail.is_service",
          header: "Service",
          custom: true,
          slotName: "job-slot-service",
          width: '7%',
          headerClass: 'column-h-text-center'
        },
        {
          field: "scheduleDetail.calibration",
          header: "Calibration",
          custom: true,
          slotName: "job-slot-calibration",
          width: '7%',
          headerClass: 'column-h-text-center'
        },
        {
          field: "scheduleDetail.is_warranty",
          header: "Warranty",
          custom: true,
          slotName: "job-slot-warranty",
          width: '7%',
          headerClass: 'column-h-text-center'
        },
        {
          field: "scheduleDetail.is_warranty",
          header: "",
          custom: true,
          slotName: "job-slot-sheet",
          width: '3%',
          headerClass: 'column-h-text-center'
        },


      ],
    };
  },
  async mounted() {


    if (this.schedule_id) {
      await this.loadSchedule(this.schedule_id);
    }

    if (this.job_id) {
      await this.loadScheduleByJobId(this.job_id);
      this.readOnly = true;
    }

    const customer_id = this.schedule.customer_id || this.$route.query.customer_id;

    if (customer_id) {
      await this.fetchAndUpdateContactDetails(customer_id);
      await this.$store.dispatch("scheduleManage/updateCurrentCustomerId", customer_id);
    }

    await this.$store.dispatch("scheduleManage/updateCurrentTable", "equipment");
  },
  // watch: {
  //   'schedule.customer': function (newVal, oldVal) {
  //     if (!newVal || !newVal.contact_id) {
  //       this.customer = {};
  //     }
  //   }
  // },
  computed: {

    pageTitle() {
      if (this.job_id) {
        return "Scheduled Job";
      } else if (this.schedule_id) {
        return "Edit Job";
      } else {
        return "Create Job";
      }
    },

    getJobId() {
      return this.schedule.job_id ? this.schedule.job_id : "";
    }

  },

  methods: {

    shouldDisplayIcon(scheduleDetail) {
      return scheduleDetail && (scheduleDetail.is_service || scheduleDetail.is_calibration || scheduleDetail.is_warranty || scheduleDetail.is_repair || scheduleDetail.is_install);
    },
    canEditScheduleEquipments(rowData) {
      return this.readOnly || rowData && rowData.scheduleDetail && rowData.scheduleDetail.job_sheet && rowData.scheduleDetail.job_sheet.job_sheet_id;
    },
    getIconClass(scheduleDetail) {
      if (scheduleDetail && scheduleDetail.job_sheet && scheduleDetail.job_sheet.job_sheet_id) {
        return 'text-green-700 stroke-current h-8 w-8 mr-1';
      } else {
        return 'text-orange-700 stroke-current h-8 w-8 mr-1';
      }
    },
    CreateJobSheet(rowData) {
      if (!rowData && !this.job_id) {
        return;
      }

      if (rowData.scheduleDetail && rowData.scheduleDetail.job_sheet && rowData.scheduleDetail.job_sheet.job_sheet_id) {

        this.$router.push({
          name: "engineer-job-sheet-edit",
          params: {
            job_sheet_id: rowData.scheduleDetail.job_sheet.job_sheet_id
          },
        });
      } else {
        this.$router.push({
          name: "engineer-job-sheet-create",
          query: {
            job_id: this.job_id,
            equipment_id: rowData.equipment.equipment_id
          },
        });
      }

    },
    handleContactCleared(selectedContact) {
      if (!selectedContact || !selectedContact.value || !selectedContact.value.contact_id) {
        this.customer = {};
        return;
      }
    },

    async fetchAndUpdateContactDetails(contact_id) {
      try {
        const response = await this.ContactService.searchContactsWithType({contact_id});
        if (response && response.length > 0) {
          this.updateCustomerDetails(response[0]);
          if (!this.schedule.customer) {
            this.schedule.customer = response[0];
          }
        } else {
          console.error('No contact details found for the given ID');
        }
      } catch (error) {
        console.error('Error fetching contact details:', error);
      }
    },
    formatTimestamp(unixTimestamp) {
      return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
    },
    handleContactSelected: function (selectedCustomer) {

      if (!selectedCustomer || !selectedCustomer.value || !selectedCustomer.value.contact_id) {
        this.customer = {};
        return;
      }

      this.updateCustomerDetails(selectedCustomer.value);
      this.$store.dispatch("scheduleManage/updateCurrentCustomerId", selectedCustomer.value.contact_id);
      this.$store.dispatch("scheduleManage/updateCurrentTable", "equipment");
    },

    updateCustomerDetails: function (selectedCustomer) {

      this.customer.address = this.formatDefaultAddress(selectedCustomer);
      this.customer.people = selectedCustomer.people;
      this.loadComments(selectedCustomer.contact_id);
    },

    formatDefaultAddress: function (selectedCustomer) {
      if (!selectedCustomer || !selectedCustomer.address) {
        return ``;
      }

      let address = selectedCustomer.address;

      return [
        address.address1,
        address.address2,
        address.address3,
        address.city,
        address.postcode,
      ]
          .filter((part) => !!part)
          .join(", ");
    },

    async loadSchedule(scheduleId) {
      try {
        const scheduleData = await this.EquipmentScheduleService.getScheduleById(scheduleId);
        this.schedule = this.formatScheduleData(scheduleData);
      } catch (error) {
        console.error("Error loading schedule:", error);
      }
    },
    async loadScheduleByJobId(jobId) {
      try {
        const scheduleData = await this.EquipmentScheduleService.getScheduleByJobId(jobId);
        this.schedule = this.formatScheduleData(scheduleData);
      } catch (error) {
        console.error("Error loading schedule:", error);
      }
    },

    loadComments: function (contact_id) {
      try {
        this.CommentService.getCommentByResourceIdAndTypeId(contact_id, "contact")
            .then(result => {

              console.log("[Comments] (mounted) comments:", result.data);
              const comments = this.getFirstCommentContent(result.data);
              this.$set(this.customer, 'comments', comments);
            })
            .catch(error => {
              console.error("Error fetching comments:", error);
            });
      } catch (error) {
        console.error(error);
      } finally {

      }
    },

    getFirstCommentContent: function (comments) {
      if (comments && comments.length > 0) {
        return comments[0].content;
      }
      return "No Notes";
    },

    formatScheduleData(schedule) {

      let formattedSchedule = JSON.parse(JSON.stringify(schedule));
      let momentObj = this.$moment.unix(formattedSchedule.scheduled_date);
      formattedSchedule.scheduled_date = momentObj.toDate();
      formattedSchedule.scheduled_time = new Date(`1970-01-01T${momentObj.format('HH:mm:ss')}`);

      return formattedSchedule;
    },
    combineAndFormatToUnix(schedule) {

      let dateString, timeString;

      if (typeof schedule.scheduled_date === 'string') {
        dateString = schedule.scheduled_date;
      } else {
        dateString = this.$moment(schedule.scheduled_date).format('DD-MM-YYYY');
      }

      if (typeof schedule.scheduled_time === 'string') {
        timeString = schedule.scheduled_time;
      } else {
        timeString = this.$moment(schedule.scheduled_time).format('HH:mm');
      }

      const dateTimeString = `${dateString} ${timeString}`;

      const unixTimestamp = this.$moment(dateTimeString, 'DD-MM-YYYY HH:mm').unix();

      let combinedSchedule = JSON.parse(JSON.stringify(schedule));
      combinedSchedule.scheduled_date = unixTimestamp;

      if (schedule.customer && schedule.customer.contact_id) {
        combinedSchedule.customer_id = schedule.customer.contact_id;
      }

      if (schedule.engineer && schedule.engineer.staff_id) {
        combinedSchedule.engineer_id = schedule.engineer.staff_id;
      }

      return combinedSchedule;
    },

    onRowClick(event) {

      this.Edit(event.data);
    },

    saveJob: async function () {
      this.isBusy = true;
      const formattedSchedule = this.combineAndFormatToUnix(this.schedule);
      try {

        const equipmentListFromStore = this.$store.state.scheduleManage.tableStates.equipment.tableData.map(item => item.scheduleDetail);

        formattedSchedule.equipment_list = equipmentListFromStore;


        if (this.schedule_id) {
          await this.EquipmentScheduleService.updateSchedule(
              this.schedule_id,
              formattedSchedule,
          );
          this.notifySuccess("Schedule updated successfully");
        } else {

          await this.EquipmentScheduleService.createSchedule(formattedSchedule);
          this.notifySuccess("Schedule created successfully");
        }
        await this.$router.push({name: "schedule"});
      } catch (error) {
        let errorMessage = this.incident_id
            ? "Couldn't update Schedule"
            : "Couldn't create Schedule";
        this.notifyError(errorMessage);
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    submitJob: async function (event) {
      event.preventDefault();
    },
  }
}
;
</script>

<style scoped>

.readonly {
  background-color: #f0f0f0;
  pointer-events: none;
}

 .total-amount {
   margin-top: 10px;
   font-weight: bold;
 }

::v-deep .column-h-text-left .p-column-header-content {
  text-align: left;
  display: block !important;
}

::v-deep .column-h-text-center .p-column-header-content {
  text-align: center;
  display: block !important;
}

::v-deep .column-h-text-right .p-column-header-content {
  text-align: right;
  display: block !important;
}
</style>


